export const environment = {
	// APP
	production: true,
	version: '7.6.4',
	local_storage_token: 'sfiu76dsjdskiuqek',
	// API'
	api_url: '/back/app/api/',
	ws_url: 'www.sosf-manager.com/websocket_update',
	// PUSHER'
	pusher_app_key: '0957d285263f3f658911',
	pusher_cluster: 'eu',
	// EXTERNAL API'
	sentry_token: 'https://b449712929694d5f90a959538a8c2846@o116063.ingest.sentry.io/5216579',
	froala_token: 'uXD2lD6E5A4D3E4B2kOPVe1f1d1Le1b1D1AWMSGSAFDTGHWsF4I4A11D9C2C5E5B1E3E3==',
	tinypng_token: 'rWjTzmfxft11JR1Bj7Sq53nrbMlkLhY3',
	google_api_url: 'https://maps.googleapis.com/maps/api/',
	google_api_token: 'AIzaSyCaV103BDogCgkZZ1urBO9n2nPCBKvR-g8',
	gouv_api_url: 'https://recherche-entreprises.api.gouv.fr/',
	insee_api_url: 'https://api.insee.fr/entreprises/sirene/V3.11/',
	insee_api_token: '3c6122c2-98dc-3e4c-b233-585fd98ca6ee',
	// DISPLAY'
	console_log_construct: false,
	console_log_error: false,
	display_pre: false,
	alert: false,
	alert_error: false,
	// LOGO
	logo_url: 'front/assets/media/',
};